











































































































































































































































import { Cliente } from "@/core/models/geral";
import { Equipamento, Faina, Proposta, PropostaEquipamentoPeriodo, RegistroOperacional } from "@/core/models/operacional";
import { PageBase } from "@/core/models/shared";
import { DespesaService, ReceitaService, TipoDespesaService, TipoReceitaService } from "@/core/services/financeiro";
import { ClienteService, ContatoService, FornecedorService, PortoService } from "@/core/services/geral";
import { EmbarcacaoService, EquipamentoService, FainaService, PropostaEquipamentoPeriodoService, PropostaService, RegistroOperacionalService, SituacaoRegistroOperacionalService } from "@/core/services/operacional";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";
import router from "@/router";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
@Component
export default class ConfiguracaoAtracacaoROA extends PageBase{
    valid: boolean= true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    roa: RegistroOperacional = new RegistroOperacional();
    roas : RegistroOperacional [] = []
    roaService:RegistroOperacionalService = new RegistroOperacionalService();
    isRoaLoading: boolean = false;
    onSearchRoa: any = null; 
    exportaExcel : boolean = false;
    exportaExcelFinanceiro : boolean = false;
    
    receitaDialog: boolean = false;
    excelOpcao: string = "padrao";
    imprimirOpcao:string = "Embarcação"


    despesaService: DespesaService = new DespesaService();
    receitaService: ReceitaService = new ReceitaService();

    tipoDespesaService = new TipoDespesaService();
    tipoDespesas: any[] = [];
    isTipoDespesaLoading: boolean = false;
    onSearchTipoDespesa: any = null;

    tipoReceitaService = new TipoReceitaService();
    tipoReceitas: any[] = [];
    isTipoReceitaLoading:boolean = false;
    onSearchTipoReceita: any = null;

    fornecedorService = new FornecedorService();
    fornecedores: any[] = [];
    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;
    
    $refs!: {
        form: HTMLFormElement
    }

    embarcacaoService = new EmbarcacaoService();
    embarcacoes: any[] = [];
    isEmbarcacaoLoading: boolean = false;
    onSearchEmbarcacao: any = null;

    embarcacoesFinancerio: any[] = [];
    isEmbarcacaoFinanceiroLoading: boolean = false;
    onSearchEmbarcacaoFinanceiro: any = null;
    
    portoService = new PortoService();
    portos: any [] =[];
    isPortoLoading: boolean = false;
    onSearchPorto: any = null;

    fainaService = new FainaService();
    fainas: Faina[] = [];
    isFainaLoading: boolean = false;
    onSearchFaina: any = null;

    periodos: PropostaEquipamentoPeriodo[] = [];
    periodoService: PropostaEquipamentoPeriodoService = new PropostaEquipamentoPeriodoService();

    isEquipamentoServicoLoading: boolean = false;
    onSearchEquipamentoServico: any = null;
    equipamentoService = new EquipamentoService()
    equipamentos: Equipamento[] = [];    

    propostaService = new PropostaService()
    propostas : Proposta [] = [];
    isPropostaLoading: boolean = false;
    onSearchProposta: any = null;

    clienteService = new ClienteService();
    clientes: Cliente[] = [];
    isClienteLoading: boolean = false;
    onSearchCliente: any = null;

    clienteFinanceiro = new ClienteService();
    clientesFinancerio: any[] = [];
    isClienteFinanceiroLoading: boolean = false;
    onSearchClienteFinanceiro: any = null;

    contatoService = new ContatoService();
    responsaveis: Cliente[] = [];
    isResponsavelLoading: boolean = false;
    onSearchResponsavel: any = null;
    
    situacaoService = new SituacaoRegistroOperacionalService()
    situacoes: any[] = [];

    atracacoes: any[] = [
        {
            value: null,
            nome: "TODOS"
        },
        {
            value: true,
            nome: "Sim"
        },
        {
            value: false,
            nome: "Não"
        }
    ];
    
    filtro: any = {
        numero: null,
        propostaId: null,
        clienteId: null,
        embarcacaoId: null,
        situacaoId: null,
        atracacao: null,
        responsavelId: null,
    };

    filtroReceitaDespesa: any = {
        numeroRo: null,
        embarcacaoId:null,
        fornecedorId: null,
        clienteId: null,
        dataEntradaAtracacao: null,
        dataSaidaAtracacao: null,
        tipoReceitaId: null,
        tipoDespesaId: null,
        nf : null,
        dataInicio: null,
        dataFim: null,
    };

    exportaExcelEquipamentoServico: boolean = false;
    filtroFornecedorEquipamentoServico: any = {
        fornecedorId: null,
        periodoId: null,
        equipamentoServicoId: null
    };

    @Watch('onSearchEmbarcacao')
    @Watch('onSearchEmbarcacaoFinanceiro')
    SearchEmbarcacao (val: string) {

        if (this.isEmbarcacaoLoading) return;
        if (this.filtro.embarcacaoId) return;

        if (this.isEmbarcacaoFinanceiroLoading) return;
        if (this.filtroReceitaDespesa.embarcacaoId) return;

        if (!val) return;

        this.isEmbarcacaoLoading = true
        this.isEmbarcacaoFinanceiroLoading = true
        this.embarcacaoService.AutoComplete(val).then(
            res => {
                this.embarcacoes = res.data;
                this.embarcacoesFinancerio = res.data;                
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isEmbarcacaoLoading = false,this.isEmbarcacaoFinanceiroLoading = false));
    }


    @Watch('onSearchProposta')
    SearchProposta ( val: string){
        if(this.isPropostaLoading) return;
        if(this.filtro.propostaId) return;
        if (!val) return;
        this.isPropostaLoading = true;
        this.propostaService.AutoComplete(val).then(
            res => {
                this.propostas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPropostaLoading = false));
    }

    @Watch('onSearchFornecedor')
    SearchFornecedor (val: string) {
        if (this.isFornecedorLoading) return;
        if (this.filtroReceitaDespesa.fornecedorId) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.fornecedorService.AutoComplete(val).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isFornecedorLoading = false));
    }

    @Watch('onSearchEquipamentoServico')
    SearchEquipamentoServico (val: string) {
        if (this.isEquipamentoServicoLoading) return;
        if (this.filtroFornecedorEquipamentoServico.equipamentoServicoId) return;
        if (!val) return;
        this.isEquipamentoServicoLoading = true
        this.equipamentoService.AutoComplete(val).then(
            res => {
                this.equipamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isEquipamentoServicoLoading = false));
    }

    @Watch('onSearchCliente')
    @Watch('onSearchClienteFinanceiro')
    SearchCliente (val: string) {

        if (this.isClienteLoading) return;
        if (this.filtro.clienteId) return;
        if (this.isClienteFinanceiroLoading) return;
        if (this.filtroReceitaDespesa.clienteId)return;
        if (!val) return;

        this.isClienteLoading = true
        this.isClienteFinanceiroLoading = true
        this.clienteService.AutoComplete(val).then(
            res => {
                this.clientes = res.data;
                this.clientesFinancerio = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isClienteLoading = false,this.isClienteFinanceiroLoading = false));
    }

    @Watch('onSearchResponsavel')
    SearchResponsavel (val: string) {

        if (this.isResponsavelLoading) return;
        if(this.filtro.responsavelId) return;
        if (!val) return;

        this.isResponsavelLoading = true
        this.contatoService.AutoComplete(val).then(
            res => {
                this.responsaveis = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isResponsavelLoading = false));
    }

    mounted() {
        this.situacaoService.ListarTudo().then(
            res=>{
                this.situacoes = res.data.items;
                this.situacoes.unshift(
                    {
                        id: null,
                        nome: 'TODOS'
                    }
                )
            },
            err => AlertSimpleErr("Aviso!", err)
        )

        this.tipoDespesaService.ListarTudo().then(
            res => {
                this.tipoDespesas = res.data.items;
                this.tipoDespesas.unshift(
                    {
                        id: null,
                        nome:"TODOS"
                    }
                )
            },
            err => AlertSimpleErr("Aviso!", err)
        )

        this.tipoReceitaService.ListarTudo().then(
            res => {
                this.tipoReceitas = res.data.items;
                this.tipoReceitas.unshift(
                    {
                        id: null,
                        nome:"TODOS"
                    }
                )
            },
            err => AlertSimpleErr("Aviso!", err)
        )

        this.periodoService.ListarTudo().then(
            res => {
                this.periodos = res.data.items
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    GerarRelatorioAtracacao(){
        if(!this.exportaExcel){
            let routeLink = this.$router.resolve({name:"impressaoAtracacao",
            query:{
                numero: this.filtro.numero,
                propostaId: this.filtro.propostaId,
                clienteId: this.filtro.clienteId,
                embarcacaoId: this.filtro.embarcacaoId,
                situacaoId: this.filtro.situacaoId,
                atracacao: this.filtro.atracacao,
                responsavelId: this.filtro.responsavelId,
            }});
            window.open(routeLink.href,'_blank')
        }else{  
            this.roaService.ExcelAtracacoes(this.filtro.numero, this.filtro.propostaId, this.filtro.situacaoId, this.filtro.atracacao, this.filtro.clienteId, this.filtro.responsavelId, this.filtro.embarcacaoId).then(
                res => {
                    this.JsonToCSV(res.data,"Atracação",true)
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }
    GerarRelatorioTernos(){
        if(!this.exportaExcel){
            let routeLink = this.$router.resolve({name:"impressaoTerno",
            query:{
                numero: this.filtro.numero,
                propostaId: this.filtro.propostaId,
                clienteId: this.filtro.clienteId,
                embarcacaoId: this.filtro.embarcacaoId,
                situacaoId: this.filtro.situacaoId,
                atracacao: this.filtro.atracacao,
                responsavelId: this.filtro.responsavelId,
            }});
            window.open(routeLink.href,'_blank')
        }
        else{
            this.roaService.ExcelTernos(this.filtro.numero, this.filtro.propostaId, this.filtro.situacaoId, this.filtro.atracacao, this.filtro.clienteId, this.filtro.responsavelId, this.filtro.embarcacaoId).then(
                res => {
                    this.JsonToCSV(res.data,"Ternos",true)
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    GerarRelatorioFaina(){
        if(!this.exportaExcel){
            let routeLink = this.$router.resolve({name:"impressaoFaina",
            query:{
                numero: this.filtro.numero,
                propostaId: this.filtro.propostaId,
                clienteId: this.filtro.clienteId,
                embarcacaoId: this.filtro.embarcacaoId,
                situacaoId: this.filtro.situacaoId,
                atracacao: this.filtro.atracacao,
                responsavelId: this.filtro.responsavelId,
            }});
            window.open(routeLink.href,'_blank')
        }
        else{
            this.roaService.ExcelFainas(this.filtro.numero, this.filtro.propostaId, this.filtro.situacaoId, this.filtro.atracacao, this.filtro.clienteId, this.filtro.responsavelId, this.filtro.embarcacaoId).then(
                res => {
                    this.JsonToCSV(res.data,"Faina",true)
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    GerarRelatorioEquipamento(){
        if(!this.exportaExcel){
            let routeLink = this.$router.resolve({name:"impressaoEquipamento",
            query:{
                numero: this.filtro.numero,
                propostaId: this.filtro.propostaId,
                clienteId: this.filtro.clienteId,
                embarcacaoId: this.filtro.embarcacaoId,
                situacaoId: this.filtro.situacaoId,
                atracacao: this.filtro.atracacao,
                responsavelId: this.filtro.responsavelId,
            }});
            window.open(routeLink.href,'_blank')
        }
        else{
            this.roaService.ExcelEquipamento(this.filtro.numero, this.filtro.propostaId, this.filtro.situacaoId, this.filtro.atracacao, this.filtro.clienteId, this.filtro.responsavelId, this.filtro.embarcacaoId).then(
                res => {
                    this.JsonToCSV(res.data,"Equipamento",true)
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    GerarExcelRO(){
        if(!this.exportaExcel){
            let routeLink = this.$router.resolve({ name: 'ralatorioRegistroOperacional',
            query:{
                numero: this.filtro.numero,
                propostaId: this.filtro.propostaId,
                clienteId: this.filtro.clienteId,
                embarcacaoId: this.filtro.embarcacaoId,
                situacaoId: this.filtro.situacaoId,
                atracacao: this.filtro.atracacao,
                responsavelId: this.filtro.responsavelId,
            }
        });
        window.open(routeLink.href, '_blank');
        }
        else {
        this.roaService.ExcelRO(this.filtro.numero, this.filtro.propostaId, this.filtro.situacaoId, this.filtro.atracacao, this.filtro.clienteId, this.filtro.responsavelId, this.filtro.embarcacaoId).then(
                res => {
                    this.JsonToCSV(res.data,"Registro Operacional",true)
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    // Financeiro

    GerarRelatorioDespesa(){
        if(!this.exportaExcelFinanceiro){
            let routeLink = this.$router.resolve({name:'ralatorioDespesa',
                query:{
                    numeroRo: this.filtroReceitaDespesa.numeroRo,
                    embarcacaoId: this.filtroReceitaDespesa.embarcacaoId,
                    fornecedorId: this.filtroReceitaDespesa.fornecedorId,
                    tipoDespesaId: this.filtroReceitaDespesa.tipoDespesaId,
                    dataInicio: this.filtroReceitaDespesa.dataInicio,
                    dataFim: this.filtroReceitaDespesa.dataFim
                }
            });
            window.open(routeLink.href,'_blank');
        }
        else {
            this.despesaService.ExcelEquipamento(this.filtroReceitaDespesa.numeroRo, this.filtroReceitaDespesa.fornecedorId, this.filtroReceitaDespesa.embarcacaoId, this.filtroReceitaDespesa.tipoDespesaId, this.filtroReceitaDespesa.dataInicio, this.filtroReceitaDespesa.dataFim).then(
                res => {
                    this.JsonToCSV(res.data,"Despesa",true);
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    GerarRelatorioReceita(opcao:string){
        if(opcao == "Embarcação"){
            let routeLink = this.$router.resolve({ name: 'relatorioReceita',
                query:{
                    numeroRo: this.filtroReceitaDespesa.numeroRo,
                    clienteId: this.filtroReceitaDespesa.clienteId,
                    embarcacaoId: this.filtroReceitaDespesa.embarcacaoId,
                    tipoReceitaId: this.filtroReceitaDespesa.tipoReceitaId,
                    dataInicio: this.filtroReceitaDespesa.dataInicio,
                    dataFim: this.filtroReceitaDespesa.dataFim
                }
            });
            window.open(routeLink.href, '_blank');
        }
        else{
            let routeLink = this.$router.resolve({ name: 'relatorioReceita2',
                query:{
                    numeroRo: this.filtroReceitaDespesa.numeroRo,
                    clienteId: this.filtroReceitaDespesa.clienteId,
                    embarcacaoId: this.filtroReceitaDespesa.embarcacaoId,
                    tipoReceitaId: this.filtroReceitaDespesa.tipoReceitaId,
                    dataInicio: this.filtroReceitaDespesa.dataInicio,
                    dataFim: this.filtroReceitaDespesa.dataFim
                }
            });
            window.open(routeLink.href, '_blank');
        }
    }

    GerarExcelReceita(){
        this.receitaService.ExcelEquipamentoReceita(this.filtroReceitaDespesa.numeroRo, this.filtroReceitaDespesa.clienteId, this.filtroReceitaDespesa.embarcacaoId, this.filtroReceitaDespesa.tipoReceitaId, this.filtroReceitaDespesa.dataInicio, this.filtroReceitaDespesa.dataFim).then(
        res => {
            this.JsonToCSV(res.data,"Receitas",true);
        },
        err => AlertSimpleErr("Aviso!", err)
        )
    }

    GerarRelatorioDespesaReceita(){
        let routeLink = this.$router.resolve({name:'impressaoDespesaReceita',
            query:{
                    numeroRo: this.filtroReceitaDespesa.numeroRo,
                    embarcacaoId: this.filtroReceitaDespesa.embarcacaoId,
                    fornecedorId: this.filtroReceitaDespesa.fornecedorId,
                    clienteId: this.filtroReceitaDespesa.clienteId,
                    dataEntradaAtracacao: this.filtroReceitaDespesa.dataEntradaAtracacao,
                    dataSaidaAtracacao: this.filtroReceitaDespesa.dataSaidaAtracacao,
                    tipoReceitaId: this.filtroReceitaDespesa.tipoReceitaId,
                    tipoDespesaId : this.filtroReceitaDespesa.tipoDespesaId,
                    nf:this.filtroReceitaDespesa.nf,
                
            }
        });
        window.open(routeLink.href,'_blank');
    }

    GerarRelatorioEquipamentoServicoFornecedor(){
        if(!this.exportaExcelEquipamentoServico){
            let routeLink = this.$router.resolve(
            { name: 'fornecedorDetalharEquipamentoServico',
                query:{
                    fornecedorId: this.filtroFornecedorEquipamentoServico.fornecedorId,
                    equipamentoServicoId: this.filtroFornecedorEquipamentoServico.equipamentoServicoId,
                    periodoId: this.filtroFornecedorEquipamentoServico.periodoId
                }
            });
            window.open(routeLink.href, '_blank');
        }
        else {
            this.fornecedorService.RelatorioServicoEquipamento(this.filtroFornecedorEquipamentoServico.fornecedorId, this.filtroFornecedorEquipamentoServico.equipamentoServicoId, this.filtroFornecedorEquipamentoServico.periodoId).then(
                res => {
                    this.JsonToCSV(res.data,"Receitas",true);
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    LimparFiltrosRO(){
        this.filtro.numero = null;
        this.filtro.propostaId = null;
        this.filtro.clienteId = null;
        this.filtro.embarcacaoId = null;
        this.filtro.situacaoId = null;
        this.filtro.responsavelId = null;
        this.$refs.form.resetValidation();
    }

    LimparFiltrosFincaneiro(){
        this.filtroReceitaDespesa.numeroRo = null;
        this.filtroReceitaDespesa.embarcacaoId = null;
        this.filtroReceitaDespesa.fornecedorId = null;
        this.filtroReceitaDespesa.clienteId = null;
        this.filtroReceitaDespesa.dataEntradaAtracacao = null;
        this.filtroReceitaDespesa.dataSaidaAtracacao = null;
        this.filtroReceitaDespesa.nf = null;
        this.filtroReceitaDespesa.dataInicio = null;
        this.filtroReceitaDespesa.dataFim = null;
        this.$refs.form.resetValidation();
    }

    LimparFiltrosEquipamentoServico(){
        this.filtroFornecedorEquipamentoServico.fornecedorId = null;
        this.filtroFornecedorEquipamentoServico.equipamentoServicoId = null;
        this.filtroFornecedorEquipamentoServico.periodoId = null;
        this.exportaExcelEquipamentoServico = false;
        this.$refs.form.resetValidation();
    }
}
